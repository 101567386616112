<template>
  <div class="loading-container" v-if="!finish">
    <van-loading color="#3756df" size="20px" />
    <p :style="'margin-left:' + (finish ? '0' : '12px')">{{ finish ? '' : '加载中 ...' }}</p>
  </div>
</template>

<script>
export default {
  props: ['finish']
}
</script>

<style lang="scss" scoped>
.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: .1rem 0;
  p {
    line-height: .4rem;
    font-size: .14rem;
    color: #999999;
    // padding-bottom: .1rem;
  }
}
</style>
