<template>
  <div id="loading-module">
    <slot />

    <loading-more :finish="loaded" />
  </div>
</template>

<script>
import LoadingMore from '@/components/loading-more'

export default {
  components: { LoadingMore },
  props: ['loading', 'loaded'],

  watch: {
    '$store.state.user.scrollTop' (val) {
      if(!document.querySelector('#loading-module')) {
        return
      }

      // 预留 5 个像素
      const scrollHeight = document.documentElement.clientHeight || document.body.scrollHeight
      
      const pageHeight = document.querySelector('#loading-module').clientHeight
       
      // loading: 如果为true，说明列表数据正在请求中，就不能再次请求
      // loaded： 如果为true，说明所有数据都加载完了，就不用继续请求了
      if ((val + scrollHeight > pageHeight - 5) && !this.loading && !this.loaded) {
        console.log('触发加载')
        this.$emit('reachBottom')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
#loading-module {
  position: relative;
  width: 100%;
}
</style>