<template>
  <div class="full-page">
    <page-load-more @reachBottom="getDoctorList()" :loading="listLoading" :loaded="listLoaded">
      <div class="header">
        <img src="@/assets/head-bg.png">
        <div class="department"><span>{{department}}</span></div>
      </div>
      <div v-if="!doctorList.length" class="page-empty">
        <img src="@/assets/empty.png">
        <p>暂无数据</p>
      </div>
      <div v-else class="doctors">
        <div class="doctor" v-for="(item, index) in doctorList" :key="index" @click="toDoctorDetail(item)">
          <div class="card-left">
            <img v-if="item.headUrl" :src="item.headUrl | formatPicture">
            <img v-else src="@/assets/default-portrait.png">
          </div>
          <div class="card-right">
            <div class="title"><span>{{item.name}}</span></div>
            <div class="doctor-tag"><span>{{item.positionName}}</span></div>
            <div class="registration-fee"><span>¥{{item.price | formatPrice}}</span></div>
            <div class="registration">
              <van-button color="#00B6A4" style="height: .58rem" @click.stop="toBooking(item)">预约</van-button>
            </div>
          </div>
        </div>
      </div>
    </page-load-more>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import PageLoadMore from '@/components/page-load-more'

  export default {
    components: {
      PageLoadMore
    },

    data() {
      return {
        doctorList: [],
        listLoading: false,
        listLoaded: true,
        query: {
          pageNo: 0,
          pageSize: 10,
          departmentId: ''
        }
      }
    },

    computed: {
      ...mapState({
        department: (state) => state.user.department,
      }),
    },

    created() {
      this.getDoctorList()
    },

    methods: {
      getDoctorList () {
        this.listLoading = true
        ++this.query.pageNo
        this.query.departmentId = this.$route.params.id
        this.$request ({
          url: '/doctors/list',
          data: this.query
        }).then( res => {
          res.result.rows.map( item => {
            this.doctorList.push(item)
          })
          this.listLoaded = res.result.total <= this.doctorList.length
          this.listLoading = false
        })
      },

      toDoctorDetail (item) {
         this.$router.push({path: '/doctor-detail', name: 'doctor-detail', params: {id: item.id}})
      },

      toBooking (item) {
        this.$router.push({path: '/date-selection', name: 'date-selection', params: {id: item.id}})
      }
    },
  }
</script>

<style lang="scss" scoped>
  .full-page {
    .header {
      position: relative;
      .department {
        font-size: .36rem;
        color: #fff;
        position: absolute;
        left: .24rem;
        top: .3rem;
      }
      img {
        width: 100%;
      }
    }

    .doctors {
      padding: .24rem;
      margin-top: -1.2rem;
      position: relative;
      .doctor {
        background: #fff;
        border-radius: .08rem;
        display: flex;
        align-items: center;
        padding: .24rem;
        margin-top: .24rem;
        &:nth-child(1) {
          margin-top: 0;
        }
        .card-left {
          margin-right: .24rem;
          border-radius: .08rem;
          overflow: hidden;
          img {
            width: 1.6rem;
            height: 1.6rem;
            object-fit: cover;
            display: block;
          }
        }
        .card-right {
          position: relative;
          flex: 1;
          .title {
            font-size: .32rem;
            margin-bottom: .08rem;
          }
          .doctor-tag {
            width: max-content;
            padding: .04rem;
            border-radius: .06rem;
            color: #00B6A4;
            border: 1px solid #00B6A4;
            font-size: .22rem;
          }
          .registration-fee {
            font-size: .36rem;
            color: #E02021;
            margin-top: 0.27rem;
          }
          .registration {
            position: absolute;
            right: 0;
            bottom: 0;
          }
        }
      }
    }
  }
  
</style>